export function orderArray(array, order, property) {
  // Make a copy to ensure we're not modifying the original data.
  array = [...array];

  return array.sort((a, b) => {
    const aIndex = order.indexOf(a[property]);
    const bIndex = order.indexOf(b[property]);

    if (aIndex < 0 && bIndex < 0) return 0;
    if (aIndex < 0) return 1;
    if (bIndex < 0) return -1;

    return aIndex - bIndex;
  });
}
