import { createRef, Component } from "react";
import styled from "styled-components";

// Utils
import { Font, Color, rem, responsive, rgba, Opacity } from "../utils/style";

// Components
import Dropdown from "./Dropdown";
import { Icons } from "../utils/react-svg";

// Styled Elements
const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${responsive.sm`
    max-width: 270px;
  `}

  ${(p) => p.contentStyle}
`;

const Label = styled.div`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  text-align: left;
  letter-spacing: 0;
  text-align: left;
  float: left;
  clear: both;
`;

const DropdownWrapper = styled.div`
  position: relative;
  margin: 8px -15px 0;
  outline: ${Color.ritualYellow};
  display: block;
  cursor: pointer;

  ${responsive.sm`
    margin: 8px 0 0 0;
  `}

  [data-whatintent="mouse"] & {
    display: none;
  }
`;

const CustomSelect = styled.button.attrs({
  className: "custom-select-button",
})`
  position: relative;
  width: 100%;
  display: none;
  margin-top: 8px;
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;
  color: ${Color.ritualBlue};

  &.isActive {
    display: block;
  }

  [data-whatintent="mouse"] & {
    display: block;
    outline: none;
  }
`;

const ProductName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 20px;
  width: 100%;
`;

const CustomSelectTrigger = styled.div.attrs({
  className: "custom-select-trigger",
})`
  border: 1px solid ${Color.ritualSecondaryBlue};
  border-radius: ${(p) => (p.rounded ? "24px" : "0")};
  padding: 10px 16px;
  font-size: ${rem(16)};
  font-weight: 300;
  line-height: ${rem(22)};
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    position: absolute;
    right: 15px;
    top: 19px;
    width: 14px;
    height: 10px;
    pointer-events: none;
  }
`;

const CustomSelectOptions = styled.div`
  display: ${(p) => (p.isOpen ? "block" : "none")};
  position: absolute;
  top: 46px;
  left: 0px;
  width: 100%;

  z-index: 40;
  padding: 24px 16px;
  background-color: ${Color.white};
  box-shadow: 0px 8px 30px -10px rgba(20, 43, 111, 0.16);
  border-top: none;
`;

const CustomSelectOptionGroup = styled.div`
  p {
    color: ${rgba(Color.ritualBlue, Opacity.light)};
    font-size: ${rem(12)};
    letter-spacing: 0.8px;
    line-height: ${rem(20)};
    text-transform: uppercase;
    margin-bottom: 8px;
    user-select: none;
  }

  &:last-child {
    div:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const CustomSelectOption = styled.div.attrs({
  className: "custom-select-option",
})`
  font-size: ${rem(16)};
  font-weight: 300;
  line-height: ${rem(22)};
  margin-bottom: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  user-select: none;

  :hover {
    opacity: ${Opacity.light};
  }

  &.current-option {
    font-weight: 500;
  }

  svg {
    position: absolute;
    top: 2px;
    right: 0px;
    width: 12px;
  }
`;

export default class MagicSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.watchClickOutside = this.watchClickOutside.bind(this);
    this.ref = createRef();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.watchClickOutside);
  }

  toggleCustomSelect() {
    const isClosed = !this.state.isOpen;

    if (isClosed) {
      this.openSelectCustom();
    } else {
      this.closeSelectCustom();
    }
  }

  openSelectCustom() {
    this.setState({
      isOpen: true,
    });

    // Add related event listeners
    document.addEventListener("click", this.watchClickOutside);
  }

  closeSelectCustom() {
    this.setState({
      isOpen: false,
    });

    // Remove related event listeners
    document.removeEventListener("click", this.watchClickOutside);
  }

  watchClickOutside(e) {
    const customSelectElement = this.ref.current;
    const didClickedOutside = !customSelectElement.contains(e.target);
    if (didClickedOutside) {
      this.closeSelectCustom();
    }
  }

  handleOptionClick(newCurrentValue) {
    this.setState({
      currentValue: newCurrentValue,
      isOpen: false,
    });

    this.props.onSelectionMade(newCurrentValue);
  }

  handleOptionEnter(e) {
    let newCurrentValue = e.target.value;

    this.setState({
      currentValue: newCurrentValue,
      isOpen: false,
    });

    this.props.onSelectionMade(newCurrentValue);
  }

  renderNativeOptions() {
    const optionGroups = this.props.optionGroups;

    // If options are nested under groups
    if (optionGroups) {
      return optionGroups.map((group) => {
        return (
          <optgroup key={group.label} label={group.label}>
            {group.options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.title}
                </option>
              );
            })}
          </optgroup>
        );
      });
      // Options are not nested
    } else {
      return this.props.options.map((option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.title}
          </option>
        );
      });
    }
  }

  renderCustomOptions() {
    const { optionGroups } = this.props;
    // If options are nested under groups
    if (optionGroups) {
      return optionGroups.map((group) => {
        return (
          <CustomSelectOptionGroup key={group.label} label={group.label}>
            <p>{group.label}</p>
            {group.options.map((option, index) => {
              let isCurrentOption =
                this.props.currentOption.value === option.value;
              return (
                <CustomSelectOption
                  onClick={this.handleOptionClick.bind(this, option.value)}
                  className={`${isCurrentOption ? "current-option" : ""}`}
                  value={option.value}
                  key={index}
                >
                  {option.title}
                  {isCurrentOption && <Icons.CheckRounded />}
                </CustomSelectOption>
              );
            })}
          </CustomSelectOptionGroup>
        );
      });
      // Options are not nested
    } else {
      return this.props.options.map((option, index) => {
        return (
          <CustomSelectOption
            onClick={this.handleOptionClick.bind(this, option.value)}
            key={index}
            value={option.value}
          >
            {option.title}
          </CustomSelectOption>
        );
      });
    }
  }

  render() {
    let { className, label, currentOption, rounded, contentStyle } = this.props;

    let { isOpen } = this.state;

    return (
      <SelectWrapper
        className={className}
        contentStyle={contentStyle}
        ref={this.ref}
      >
        <Label id="productLabel">{label}</Label>
        <DropdownWrapper>
          <Dropdown
            onChange={this.handleOptionEnter.bind(this)}
            value={currentOption.value}
            aria-labelledby="productLabel"
            rounded={rounded}
          >
            {this.renderNativeOptions()}
          </Dropdown>
        </DropdownWrapper>

        {/* <!-- Hide the custom select from Assistive Tech (e.g. Screen Readers) using aria-hidden --> */}
        <CustomSelect
          className={`customSelect ${isOpen ? "isOpen" : ""}`}
          onClick={this.toggleCustomSelect.bind(this)}
          aria-hidden={!isOpen}
        >
          <CustomSelectTrigger
            isOpen={isOpen}
            rounded={rounded}
            contentStyle={contentStyle}
          >
            <ProductName>{this.props.currentOption.title}</ProductName>
            {isOpen ? <Icons.CaretUpRounded /> : <Icons.CaretDown />}
          </CustomSelectTrigger>
          <CustomSelectOptions isOpen={isOpen}>
            {this.renderCustomOptions()}
          </CustomSelectOptions>
        </CustomSelect>
      </SelectWrapper>
    );
  }
}
